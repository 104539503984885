<template>
	<!-- コントロールボタンの配置 -->
	<div 
		id="controls" 
		class="control-buttons" 
	>
		<svg 
			xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 485 485"
			class="control-svg"
		>
			<g 
				id="control-buttons-up"
				class="control-buttons-up control-buttons"
				@mousedown="startMovement('up', $event)" 
				@mouseup="stopMovement($event)" 
				@mouseleave="stopMovement($event)" 
				@touchstart="handleTouchStart('up', $event)" 
				@touchend="handleTouchEnd($event)"
			>
				<path id="control-up" class="control-path"
					d="M71.4,71.4l171.1,171.1,171.1-171.1c-94.5-94.5-247.7-94.5-342.2,0Z" />
				<g id="button-up" class="control-button">
					<circle class="control-circle" cx="242.5" cy="100.5" r="93.5" transform="translate(0 200.9) rotate(-45)"/>
					<path class="control-arrow" d="M194.4,123.6l41.5-46.8c3.5-3.9,9.7-3.9,13.2,0l41.5,46.8" />
				</g>
			</g>
			<g 
				id="control-buttons-right"
				class="control-buttons-right control-buttons"
				@mousedown="startMovement('right', $event)" 
				@mouseup="stopMovement($event)" 
				@mouseleave="stopMovement($event)" 
				@touchstart="handleTouchStart('right', $event)" 
				@touchend="handleTouchEnd($event)"
			>
				<path id="control-right" class="control-path"
					d="M413.6,71.4l-171.1,171.1,171.1,171.1c94.5-94.5,94.5-247.7,0-342.2Z" />
				<g id="button-right" class="control-button">
					<circle class="control-circle" cx="384.5" cy="242.5" r="93.5"
						transform="translate(-58.9 342.9) rotate(-45)" />
					<path class="control-arrow" d="M361.4,194.4l46.8,41.5c3.9,3.5,3.9,9.7,0,13.2l-46.8,41.5" />
				</g>
			</g>
			<g 
				id="control-buttons-down"
				class="control-buttons-down control-buttons"
				@mousedown="startMovement('down', $event)" 
				@mouseup="stopMovement($event)" 
				@mouseleave="stopMovement($event)" 
				@touchstart="handleTouchStart('down', $event)" 
				@touchend="handleTouchEnd($event)"
			>
				<path id="control-down" class="control-path"
					d="M413.6,413.6l-171.1-171.1-171.1,171.1c94.5,94.5,247.7,94.5,342.2,0Z" />
				<g id="button-down" class="control-button">
					<circle class="control-circle" cx="242.5" cy="384.5" r="93.5"
						transform="translate(-200.9 284.1) rotate(-45)" />
					<path class="control-arrow" d="M290.6,361.4l-41.5,46.8c-3.5,3.9-9.7,3.9-13.2,0l-41.5-46.8" />
				</g>
			</g>
			<g 
				id="control-buttons-left"
				class="control-buttons-left control-buttons"
				@mousedown="startMovement('left', $event)" 
				@mouseup="stopMovement($event)" 
				@mouseleave="stopMovement($event)" 
				@touchstart="handleTouchStart('left', $event)" 
				@touchend="handleTouchEnd($event)"
			>
				<path id="control-left" class="control-path"
					d="M71.4,413.6l171.1-171.1L71.4,71.4c-94.5,94.5-94.5,247.7,0,342.2Z" />
				<g id="button-left" class="control-button">
					<circle class="control-circle" cx="100.5" cy="242.5" r="93.5"
						transform="translate(-142 142.1) rotate(-45)" />
					<path class="control-arrow" d="M123.6,290.6l-46.8-41.5c-3.9-3.5-3.9-9.7,0-13.2l46.8-41.5" />
				</g>
			</g>
		</svg>
	</div>
</template>
<script>
	export default {
  name: 'ControlButton',
  data() {
    return {
			isMoving: false, // カメラが現在移動中かどうか
      moveDirection: null, // 現在の移動方向 'up', 'down', 'left', 'right' 
      moveSpeed: 2.8, // カメラが移動する速度
		}
	},
	props: {
		jsonRotationY:{
			type: Number,
      required: true
		},
		roomRig:{
			type: HTMLElement,
			required: true
		},
		roomCamera:{
			type: HTMLElement,
      required: true
		}
	},
	watch: {
		// jsonRotationYが変更されたときにボタンの方向を調整
		jsonRotationY(){
    	this.adjustButtonDirections();
  	},
	},
	mounted() {
		// コンポーネントがマウントされた後にボタンの方向を調整
    this.adjustButtonDirections();
		// ボタンのタッチイベントを設定
		const buttons = document.querySelectorAll('.control-path');
    buttons.forEach(button => {
      button.addEventListener('touchstart', (event) => {
        event.preventDefault(); // デフォルトのタッチ動作をキャンセル
        this.handleTouchStart(event.target.dataset.direction, event);
      });
      button.addEventListener('touchend', (event) => {
        event.preventDefault(); // デフォルトのタッチ動作をキャンセル
        this.handleTouchEnd(event);
      });
    });
	},
	methods: {
		/*******************************************
     * コントロールボタンでカメラを動かすメソッド
     ******************************************/
		 startMovement(direction, event) {
      if (this.isMoving) {
        return; // 既に移動中の場合は何もしない
      }
			// すべてのボタンから'is-current'クラスを削除
			this.removeActiveClass();
			// タッチされたボタンに'is-current'クラスを追加
			if (event) {
				const controlCircle = event.currentTarget.querySelector('.control-circle');
				if (controlCircle) {
      		controlCircle.classList.add('is-current');
    		}
			} else {
				console.error("Event is undefined or currentTarget is missing:", event);
			}
      this.isMoving = true;
      this.moveDirection = direction; // 現在の移動方向を格納
      this.moveCamera(); // 初回呼び出し
    },
    stopMovement(event) {
      this.isMoving = false;
      this.moveDirection = null;
			// アニメーションがリクエストされている場合
      if (this.movementRequestId) {
        cancelAnimationFrame(this.movementRequestId); // アニメーションフレームをキャンセル
        this.movementRequestId = null; // 空にする
      }
			// タッチが終了したら'is-current'クラスを削除
			if (event) {
				const controlCircle = event.currentTarget.querySelector('.control-circle');
				if (controlCircle) {
					controlCircle.classList.remove('is-current');
				}
			} else {
				console.error("Event is undefined or currentTarget is missing:", event);
			}
    },
    handleTouchStart(direction, event) {
      event.preventDefault(); // タッチイベントのデフォルト動作を抑制
      this.startMovement(direction, event); // 指定された方向でカメラの移動を開始
    },
    handleTouchEnd(event) {
      event.preventDefault(); // タッチイベントのデフォルト動作を抑制
      this.stopMovement(event); // カメラの移動を停止
    },
    removeActiveClass() {
      const circles = document.querySelectorAll('.control-circle');
      circles.forEach(circle => {
        circle.classList.remove('is-current');
      });
    },
    resetCameraRotation() { 
      const camera = this.roomCamera;
      if (!camera) {
        console.error('Camera is not found.');
        return;
      }
      const cameraObject = camera.object3D;
      cameraObject.rotation.set(0, 0, 0); // カメラの回転をリセット
    },
    adjustButtonDirections() { // ボタンの方向を調整
      const rotationY = this.jsonRotationY;
      const controlSvg = document.querySelector('.control-svg');
      if (controlSvg) {
        controlSvg.style.transform = `rotate(${rotationY}deg)`; // `rotationY`の値に基づいて要素を回転
      }
    },
    moveCamera() {
			// 'roomRig'と'roomCamera'の参照を取得
      const rig = this.roomRig;
      const camera = this.roomCamera;
      if (!rig || !camera) {
        console.error('Camera Rig or Camera is not found.');
        return;
      }
			// 'camera'オブジェクトを取得
      const cameraObject = camera.object3D;
			// カメラの向きに基づいて前方と右方向のベクトルを計算
      const forward = new THREE.Vector3(0, 0, -1).applyQuaternion(cameraObject.quaternion);
      const right = new THREE.Vector3(1, 0, 0).applyQuaternion(cameraObject.quaternion);
			// 移動ベクトルを初期化
      const moveVector = new THREE.Vector3();
			// 現在の移動方向に基づいて移動ベクトルを設定
      switch (this.moveDirection) {
        case 'up':
          moveVector.set(forward.x, 0, forward.z).normalize();
          break;
        case 'down':
          moveVector.set(-forward.x, 0, -forward.z).normalize();
          break;
        case 'left':
          moveVector.set(-right.x, 0, -right.z).normalize();
          break;
        case 'right':
          moveVector.set(right.x, 0, right.z).normalize();
          break;
      }
			// 移動ベクトルに移動速度を掛けて、実際の移動量を計算
      const moveAmount = moveVector.multiplyScalar(this.moveSpeed);
			// カメラの移動をアニメーション
      function animate() { // `animate`を定義
        if (!this.isMoving) {
          return;
        }
				// `rig`の`velocity`属性を設定して移動
				rig.setAttribute('velocity', {
					x: moveAmount.x,
					y: moveAmount.y,
					z: moveAmount.z
				});
				// 次のフレームで`animate`関数を再呼び出しアニメーションを継続
				this.movementRequestId = requestAnimationFrame(animate.bind(this));
      }
			// アニメーションを開始
      animate.call(this);
    }
	}
}
</script>
<style scoped>
.control-buttons {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.control-buttons {
  user-select: none; /* テキスト選択を無効 */
}
.control-button {
  user-select: none; /* テキスト選択を無効 */
  touch-action: manipulation; /* タッチ操作のデフォルトの動作を制御 */
}
.control-path {
  pointer-events: auto; /* タッチイベントがボタンに影響を与えるようにする */
}
.control-path, .control-arrow {
	fill: none;
}
.control-arrow {
	stroke-width: 10px;
}
.control-arrow, .control-circle {
	stroke: #202020;
	stroke-miterlimit: 10;
}
.control-circle {
	fill: #fff;
	fill-opacity: .6;
	stroke-width: 4px;
	-webkit-filter: drop-shadow(4px 4px 10px -5px rgba(0, 0, 0, 0.3));
 	filter: drop-shadow(4px 4px 10px -5px rgba(0, 0, 0, 0.3));
}
.control-circle.is-current {
	fill-opacity: 1;
}
a-entity {
  transition: transform 0.2s ease-out;
}
</style>